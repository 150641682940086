import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ScrollToTop } from "./components/ScrollToTop";
import { Header } from "./components/Header";
import { Disclaimer } from "./components/Disclaimer";

import { LandingPage } from "./pages/LandingPage";
import { About } from "./pages/About";
import { Blog } from "./pages/Blog";
import { WIP } from "./pages/WIP";
import { AccessibilityEssential } from "./pages/AccessibilityEssential";
import { WorkPlay } from "./pages/WorkPlay";
import { AnimatingAccessibility } from "./pages/AnimatingAccessibility";
import { AccessibilityTesting } from "./pages/AccessibilityTesting";
import { FocusTrap } from "./pages/FocusTrap";
import { Tarantino } from "./pages/Tarantino";
import { AnimationFriday } from "./pages/AnimationFriday";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(() => {
    const popupShown = localStorage.getItem("popupShown");
    const lastVisitTimestamp = localStorage.getItem("lastVisitTimestamp");
    const now = new Date().getTime();
    const oneDay = 24 * 60 * 60 * 1000;

    return (
      !popupShown || (lastVisitTimestamp && now - lastVisitTimestamp > oneDay)
    );
  });

  useEffect(() => {
    if (showPopup) {
      localStorage.setItem("popupShown", "true");
    }
  }, [showPopup]);

  const handlePopupClose = () => {
    setShowPopup(false);
    localStorage.setItem("lastVisitTimestamp", new Date().getTime().toString());
  };

  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          {showPopup && <Disclaimer onClose={handlePopupClose} />}
          <Routes>
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/workplay" element={<WorkPlay />} />
            <Route exact path="/workplay/tarantino" element={<Tarantino />} />
            <Route
              exact
              path="/workplay/animationfriday"
              element={<AnimationFriday />}
            />

            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/blog/wip" element={<WIP />} />
            <Route
              exact
              path="/blog/accessibility"
              element={<AccessibilityEssential />}
            />
            <Route
              exact
              path="/blog/animation-accessibility"
              element={<AnimatingAccessibility />}
            />
            <Route
              exact
              path="/blog/accessibility-testing"
              element={<AccessibilityTesting />}
            />
            <Route exact path="/blog/focus-trap" element={<FocusTrap />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
