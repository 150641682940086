import Tarantino from "../assets/tarantino2.mp4";
import Picked1 from "../assets/picked1.png";
import Picked2 from "../assets/picked2.png";
import Picked3 from "../assets/picked3.jpeg";
import Picked4 from "../assets/picked4.jpeg";
import Picked5 from "../assets/picked5.jpeg";
import Picked6 from "../assets/picked6.jpeg";
import Picked7 from "../assets/picked7.jpeg";
import Name from "../assets/name.mp4";
import cows from "../assets/cows.mp4";
import Jake from "../assets/jake.mp4";
import Liv from "../assets/LivStrom.mp4";
import WPJohanna from "../assets/johanna.mp4";
import IIoW from "../assets/IIoW.webp";
import Friday from "../assets/friday.webp";

export const WorkPlayData = [
  {
    id: 1,
    title: "That's my name",
    video: WPJohanna,
    type: "video",
  },
  {
    id: 2,
    title: "codepen.io",
    video: Tarantino,
    type: "video",
    link: "https://codepen.io/collection/rxqObB?cursor=eyJwYWdlIjoxfQ==",
  },
  {
    id: 3,
    title: "... an animated comic",
    video: Liv,
    type: "video",
  },

  {
    id: 4,
    title: "I am working on it",
    alt: "#",
    image: IIoW,
    type: "image",
  },
];

export const WorkPlayDataPage = [
  // {
  //   id: 1,
  //   title: "I am working on it",
  //   image: IIoW,
  //   type: "image",
  // },
  {
    id: 2,
    title: "Budget vs Facial Hair Death Rates in Tarantino Movies",
    video: Tarantino,
    type: "video",
    to: "/workplay/tarantino",
  },
  {
    id: 3,
    title: "#animationFriday",
    alt: "grid of pens from codepen.io",
    image: Friday,
    type: "image",
    to: "/workplay/animationfriday",
  },
];

export const animationFridayData = [
  {
    id: 1,
    title: "Invasive cows",
    video: cows,
    type: "video",
    link: "https://codepen.io/idsintehittapa/pen/GRbbjpy",
  },
  {
    id: 2,
    title: "Tarantino",
    video: Tarantino,
    type: "video",
    link: "https://codepen.io/collection/rxqObB?cursor=eyJwYWdlIjoxfQ==",
  },
  {
    id: 3,
    title: "Layered svg Text",
    video: Name,
    type: "video",
    link: "https://codepen.io/idsintehittapa/pen/KKOGQpN",
  },
  {
    id: 4,
    title: "Jake Button",
    video: Jake,
    type: "video",
    link: "https://codepen.io/idsintehittapa/pen/JjQXbRW",
  },
];

export const pickedData = [
  {
    id: 1,
    alt: "#",
    src: Picked1,
  },
  {
    id: 2,
    alt: "#",
    src: Picked2,
  },
  {
    id: 3,
    alt: "#",
    src: Picked3,
  },
  {
    id: 4,
    alt: "#",
    src: Picked4,
  },
  {
    id: 5,
    alt: "#",
    src: Picked5,
  },
  {
    id: 6,
    alt: "#",
    src: Picked6,
  },
  {
    id: 7,
    alt: "#",
    src: Picked7,
  },
];
