import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { Footer } from "../components/Footer";
import { WorkPlayDataPage } from "../data/WorkPlayData";

import "./blog.css";

export const WorkPlay = () => {
  const videoRefs = useRef({});

  useEffect(() => {
    const currentVideos = videoRefs.current;

    const handleVideoEnd = (video) => {
      video.pause(); // Ensure video is paused
      setTimeout(() => {
        video.currentTime = 0; // Reset to start
        video.play();
      }, 3000); // 2 second delay
    };

    Object.values(currentVideos).forEach((video) => {
      if (video) {
        // Remove any existing listeners first
        video.removeEventListener("ended", () => handleVideoEnd(video));
        // Add the event listener
        video.addEventListener("ended", () => handleVideoEnd(video));
      }
    });

    return () => {
      Object.values(currentVideos).forEach((video) => {
        if (video) {
          video.removeEventListener("ended", () => handleVideoEnd(video));
        }
      });
    };
  }, []);

  return (
    <>
      <main>
        <article className="blog__wrapper">
          <div className="inner a">
            <div className="blog__wrapper-copy">
              <h1 className="blog__wrapper-title">work</h1>
              <p className="blog__wrapper-subtitle">.play</p>
            </div>
          </div>
        </article>
        <article className="blog__thumb-posts">
          <ul className="blog__content-grid ">
            {WorkPlayDataPage.map((data) => (
              <li className="blog__content-list" key={data.id}>
                <Link to={data.to} className="blog__link">
                  <div>
                    {data.type === "image" && (
                      <img
                        srcSet={
                          (`${data.image}?width=300 300w`,
                          `${data.image}?width=400 400w`)
                        }
                        alt={data.alt}
                        className="blog__content-img"
                        loading="lazy"
                        width="100%"
                        height="auto"
                      />
                    )}
                    {data.type === "video" && (
                      <video
                        ref={(el) => (videoRefs.current[data.id] = el)}
                        src={data.video}
                        type="video/mp4"
                        load="lazy"
                        width="100%"
                        height="auto"
                        playsInline="playsinline"
                        className="blog__content-video"
                        muted={true}
                        autoPlay={true}
                      />
                    )}
                  </div>
                  <div>
                    <p className="blog__content-date">{data.date}</p>
                    <p className="blog__content-title">{data.title}</p>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </article>
      </main>

      <Footer />
    </>
  );
};
