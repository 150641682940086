import React from "react";
import { Footer } from "../components/Footer";
import { Link } from "react-router-dom";
import { WorkPlayDataPage } from "../data/WorkPlayData";
import { animationFridayData } from "../data/WorkPlayData";
import { pickedData } from "../data/WorkPlayData";

import "./blog.css";
import { WorkPlay } from "../components/WorkPlay";

export const AnimationFriday = () => {
  return (
    <>
      <main>
        <article>
          <div className="inner a">
            <div className="blog__wrapper-copy-post">
              <div className="back__blog-wrapper">
                <Link to="/workplay" className="back__blog">
                  work.play
                </Link>
              </div>
              <h1 className="work__wrapper__title-post">
                {WorkPlayDataPage[1].title}
              </h1>
              <div className="blog__copy-line">
                <span></span>
              </div>
            </div>

            <div className="blog__copy-wrapper">
              <div className="blog__copy-text">
                <p>
                  Every Friday, I set aside time to play with animations. It
                  started as a way to learn new things and turned into a weekly
                  ritual of making stuff move around on screens. Sometimes it
                  works out, sometimes it doesn't, but it's always fun.
                </p>
              </div>

              <div className="blog__copy-asset">
                <WorkPlay items={animationFridayData} variant="blog" />
              </div>

              <div className="blog__copy-text">
                <p>
                  Some of these experiments have been featured on CodePen, which
                  is pretty cool and unexpected. The Tarantino
                  beard-death-counter even made it into their Spark newsletter,
                  proving that weird ideas sometimes find their people.
                </p>
              </div>

              <div className="blog__copy-asset">
                <ul className="picked_grid">
                  {pickedData.map((data) => (
                    <li key={data.src}>
                      <img src={data.src} alt={data.alt} />
                    </li>
                  ))}
                </ul>
              </div>

              <div className="blog__copy-text">
                <p>
                  See the full{" "}
                  <a href="https://codepen.io/collection/rxqObB?sort_order=desc&sort_by=id">
                    #animationFriday collection
                  </a>
                </p>
              </div>

              <div className="the__end">
                <p>THE END.</p>
                <div className="blog__copy__small-line">
                  <span></span>
                </div>
                <Link to="/workplay" className="back__blog end">
                  How about some other work.play?
                </Link>
              </div>
            </div>
          </div>
        </article>
      </main>

      <Footer />
    </>
  );
};
