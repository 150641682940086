import React from "react";
import { WorkPlayData } from "../data/WorkPlayData";
import { WorkPlay } from "./WorkPlay";

import "./workPlayThumb.css";

export const WorkPlayThumb = () => {
  return (
    <article className="wp__wrapper">
      <div className="inner">
        <p className="small__title">Selected projects</p>
        <p className="wp__inner-work">Work</p>
        <p className="wp__inner-play">Play</p>
      </div>
      <WorkPlay items={WorkPlayData} variant="default" />
      {/* <Link to="/workplay" className="square">
        view all projects
      </Link> */}
    </article>
  );
};
