import React from "react";
import { Footer } from "../components/Footer";
import { Link } from "react-router-dom";
import TarantinoVideo from "../assets/tarantino2.mp4";
import { WorkPlayDataPage } from "../data/WorkPlayData";

import "./blog.css";

export const Tarantino = () => {
  return (
    <>
      <main>
        <article>
          <div className="inner a">
            <div className="blog__wrapper-copy-post">
              <div className="back__blog-wrapper">
                <Link to="/workplay" className="back__blog">
                  work.play
                </Link>
              </div>
              <h1 className="work__wrapper__title-post">
                {WorkPlayDataPage[0].title}
              </h1>
              <div className="blog__copy-line">
                <span></span>
              </div>
            </div>

            <div className="blog__copy-wrapper">
              <div className="blog__copy-text">
                <p>
                  If you've ever considered a role in a Tarantino film - and
                  let's be honest, who hasn't fantasized about a dramatically
                  violent exit from this mortal coil - might I suggest leaving
                  your facial grooming habits at the door. In what can only be
                  described as cinema's most spurious correlation, it appears
                  that sporting a beard in Tarantino's universe is about as wise
                  as bringing a book to a gunfight.
                </p>
              </div>

              <div className="blog__copy-asset">
                <video
                  src={TarantinoVideo}
                  type="video/mp4"
                  load="lazy"
                  width="100%"
                  height="auto"
                  playsInline="playsinline"
                  loop={true}
                  muted={true}
                  autoPlay="autoplay"
                >
                  Your browser does not support the video tag.
                </video>
              </div>

              <div className="blog__copy-text">
                <p>
                  In what started as a questionable use of my time - which,
                  let's face it, describes most worthwhile pursuits - I found
                  myself counting dead bearded men in Tarantino films. Like some
                  sort of deranged census taker for the cinematically deceased,
                  I tracked facial hair patterns against survival rates. The
                  results, while statistically significant only in the most
                  liberal interpretation of the term, might just save your life
                  should you ever find yourself in a Tarantino script.
                </p>
              </div>

              <div className="blog__copy-text">
                <p>Made by me</p>
                <p>
                  Animated with <a href="https://gsap.com/">GSAP</a>
                </p>
                <p>
                  Hosted on{" "}
                  <a href="https://codepen.io/idsintehittapa/pen/gOyeeLq">
                    CodePen
                  </a>
                </p>
                <p>
                  Featured in CodePen's{" "}
                  <a href="https://codepen.io/spark/387">
                    Spark newsletter (Issue 387)
                  </a>{" "}
                  ,which was pretty cool
                </p>
              </div>

              <div className="the__end">
                <p>THE END.</p>
                <div className="blog__copy__small-line">
                  <span></span>
                </div>
                <Link to="/workplay" className="back__blog end">
                  How about some other work.play?
                </Link>
              </div>
            </div>
          </div>
        </article>
      </main>

      <Footer />
    </>
  );
};
