import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import "./workPlay.css";

export const WorkPlay = ({ items, variant = "default" }) => {
  const imageList = useRef(null);
  const testimonialList = useRef(null);
  const baseIndex = 1;
  const sectionRef = useRef(null);
  const videoRef = useRef(null);

  const [isSectionInView, setIsSectionInView] = useState(false);
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const isInView = entry.isIntersecting;
          setIsSectionInView(isInView);

          // If the section is not in view, stop the active video
          if (!isInView) {
            const imageElements = imageList.current.children;
            const activeElement = imageElements[activeItemIndex];

            if (activeElement.firstChild.tagName === "VIDEO") {
              activeElement.firstChild.pause();
              activeElement.firstChild.currentTime = 0;
            }
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    observer.observe(sectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, [activeItemIndex]);

  const nextSlide = () => {
    const totalItems = items.length;
    const currentActiveIndex = activeItemIndex;
    const nextIndex = (currentActiveIndex + 1) % totalItems;

    setActiveItemIndex(nextIndex);
  };

  const prevSlide = () => {
    const totalItems = items.length;
    const currentActiveIndex = activeItemIndex;
    const prevIndex = (currentActiveIndex - 1 + totalItems) % totalItems;

    setActiveItemIndex(prevIndex);
  };

  useEffect(() => {
    gsap.to(testimonialList.current.children[0], {
      opacity: 1,
    });
  }, []);

  useEffect(() => {
    const imageElements = imageList.current.children;
    const totalItems = imageElements.length;

    // Stop all videos
    Array.from(imageElements).forEach((element) => {
      if (element.firstChild.tagName === "VIDEO") {
        element.firstChild.pause();
        element.firstChild.currentTime = 0;
      }
    });

    const currentElement = imageElements[activeItemIndex];

    // Start the video for the current item if the section is in view
    if (isSectionInView && currentElement.firstChild.tagName === "VIDEO") {
      currentElement.firstChild.play();
    }

    gsap.to(currentElement, {
      ease: "power2.out",
      duration: 0.5,
      transform: "translate(0%, 0%) rotate(-5deg)",
      zIndex: totalItems,
    });

    if (activeItemIndex === 0) {
      gsap.to(imageElements[totalItems - 1], {
        ease: "power2.out",
        duration: 0.5,
        transform: "translate(1%, 0%) rotate(-5deg)",
        zIndex: baseIndex,
      });
    } else {
      gsap.to(imageElements[activeItemIndex - 1], {
        ease: "power2.out",
        duration: 0.5,
        transform: "translate(-2%, 0%)",
        zIndex: baseIndex,
      });
    }

    if (activeItemIndex === totalItems - 1) {
      gsap.to(imageElements[0], {
        ease: "power2.out",
        duration: 0.5,
        transform: "translate(2%, 0%) rotate(5deg)",
        zIndex: baseIndex,
      });
    } else {
      gsap.to(imageElements[activeItemIndex + 1], {
        ease: "power2.out",
        duration: 0.5,
        transform: "translate(-3%, 0%) rotate(5deg)",
        zIndex: baseIndex,
      });
    }
  }, [activeItemIndex, isSectionInView]);

  return (
    <div
      className={`carousel__wrapper carousel__wrapper--${variant}`}
      ref={sectionRef}
    >
      <div className="carousel__content">
        <div className="t-image">
          <ul ref={imageList} className="carousel__content-images">
            {items.map((data, index) => (
              <li
                key={index}
                className={
                  activeItemIndex === index
                    ? "carousel__content-li active"
                    : "carousel__content-li"
                }
              >
                {data.type === "image" && (
                  <img
                    src={data.image}
                    alt={data.title}
                    loading="lazy"
                    width="100%"
                    height="auto"
                  />
                )}
                {data.type === "video" && (
                  <video
                    ref={videoRef}
                    src={data.video}
                    type="video/mp4"
                    load="lazy"
                    width="100%"
                    height="auto"
                    playsInline="playsinline"
                    loop={true}
                    muted={true}
                    autoPlay={
                      activeItemIndex === index && JSON.parse(isSectionInView)
                    }
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
        <ul ref={testimonialList} className="carousel__content-titles">
          {items.map((data, index) => (
            <li
              key={index}
              className={activeItemIndex === index ? "active" : "none"}
            >
              <p className="carousel__content__titles-copy">
                {data.link ? (
                  <a href={data.link} target="_blank" rel="noopener noreferrer">
                    {data.title}
                  </a>
                ) : (
                  data.title
                )}
              </p>
            </li>
          ))}
        </ul>
        <button
          onClick={prevSlide}
          className="wp__button-left"
          aria-label="previous"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 21 8"
            className="arrow__wp"
          >
            <path
              d="M293.15 274.51h34.93v9.43h-34.93z"
              fill="none"
              transform="matrix(.58 0 0 .81 -169.47 -223.3)"
            />
            <path
              d="M254.73 285.01v-2.08h64.51l-9.38-6.76H314l10.83 7.8-10.83 7.8h-4.13l9.38-6.75h-64.51Z"
              transform="matrix(-.26 0 0 -.34 84.83 101.22)"
              className="arrow__body"
            />
          </svg>
        </button>
        <button
          onClick={nextSlide}
          className="wp__button-right"
          aria-label="next"
        >
          <svg
            className="arrow__wp"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 21 8"
          >
            <path
              d="M293.15 274.51h34.93v9.43h-34.93z"
              fill="none"
              transform="matrix(.58 0 0 .81 -169.47 -223.3)"
            />
            <path
              d="M254.73 285.01v-2.08h64.51l-9.38-6.76H314l10.83 7.8-10.83 7.8h-4.13l9.38-6.75h-64.51Z"
              transform="matrix(.26 0 0 .34 -65.56 -93.65)"
              className="arrow__body"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
